<template>
  <div class="Prize" v-if="view">
    <img :style="Height" :src="img" v-bind:class="{fadein:fadein}" ref="img">
    <div class="close" v-if="closeView" @click="closeImg"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: false,
      Height: '',
      img: '',
      HeightData: 0.1,
      closeView: false,
      fadein: false
    }
  },
  mounted() {

  },
  methods: {
    openImg(img) {
      this.view = true
      this.img = img
      setTimeout(() => {
        this.fadein = true
      }, 10)
      setTimeout(() => {
        this.closeView = true
      }, 500)
    },

    closeImg() {
      this.Height = ''
      this.view = false
      this.HeightData = 0.1
      this.closeView = false
    }
  }
}
</script>

<style scoped lang="scss">
.Prize {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;

  img {
    position: absolute;
    left: 50%;
    width: 280px;
    height: 400px;
    margin-left: -140px;
    top: 50%;
    margin-top: -200px;
  }

  .close {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/map/close.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: -201px;
    toggle-group: 0;
    background-color: #FFFFFF;
  }
}

.fadein {
  animation: fadein .5s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    //transform: scale(1)
    transform: translate(0, 100%) scale(0.1);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}

</style>
