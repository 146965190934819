import request from "../utils/request";

//认领随机坐标
export const claim = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "introduce/claim",
        method: "get",
        data: query,
    });
};

//查询已经启用画布与已认领坐标数据
export const fixed = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "introduce/fixed",
        method: "get",
        data: query,
    });
};


//检查是否占用作品
export const InspectWork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "introduce/inspect",
        method: "post",
        data: query,
    });
};

//放弃与删除占用未创作坐标
export const DeleteWork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "introduce/work/delete",
        method: "post",
        data: query,
    });
};


//检查用户是否存在该未创作画布
export const InitWorkFind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "introduce/work/find",
        method: "get",
        params: query,
    });
};

export const NotSelecta = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "introduce/NotSelecta",
        method: "get",
        params: query,
    });
};


export const WorkNumbers = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "create/user/work",
        method: "get",
        params: query,
    });
};



